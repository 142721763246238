import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import "./Landing.scss";
import PrimaryButton from "../../Components/UI/PrimaryButton/PrimaryButton";
import {
  arrowCircleLeft,
  arrowCircleRight,
  award,
  bgImg1,
  bgImg2,
  bgImg3,
  bgImg4,
  business1,
  business10,
  business11,
  business12,
  business13,
  business14,
  business15,
  business2,
  business3,
  business4,
  business5,
  business6,
  business7,
  business8,
  business9,
  clockEven,
  consultancyIcon,
  designIcon,
  emptyStar,
  faceSmile,
  fillStar,
  laptop,
  line,
  main,
  maintenanceIcon,
  mobile,
  mobileIcon,
  owner,
  profile1,
  profile2,
  profile3,
  qoutes,
  rocketLaunch,
  testingIcon,
  umair,
  webIcon,
  zain,
  zeeshan,
} from "../../Assets";
import Rating from "react-rating";
import { Link } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import NumberCounter from "number-counter";
import { Carousel } from "react-responsive-carousel";

const Landing = ({ setIsNav }) => {
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingIndex, setRatingIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [teamIndex, setTeamIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(0);

  const positionsList = [
    {
      title: "UI / UX Design",
      icon: designIcon,
      description:
        "We understand the primary importance of interface design and user experience and We will help you out transforming the most friendly way of interaction between user and software.",
    },
    {
      title: "Custom web development",
      icon: webIcon,
      description:
        "Many mobile app clients also choose to make their software available as a web app, and some only need a web software. Going with a web-only software is most common for custom internal company tools that are not consumer facing.",
    },
    {
      title: "Custom Mobile development",
      icon: mobileIcon,
      description:
        "From blueprint, to design, to coding.. we've got your back! We can build your customer facing or internal use mobile app for iOS or Android (most people do both).Once you've approved your first version for launch we handle the Apple and Google submission process for you.",
    },
    {
      title: "Q / A Testing",
      icon: testingIcon,
      description:
        "Our QA team is well versed in what they do. We follow a process that includes functional/non-functional testing, unit testing, security testing, integration/regression testing and user acceptance testing.",
    },
    {
      title: "IT Maintenance & Support",
      icon: maintenanceIcon,
      description:
        "One of the biggest questions we get is can you help maintain the app after it's built?. The answer is ABSOLUTELY. We can host, maintain, and continuously upgrade your app. In fact, we prefer to work with clients who have long term plans for their app. Consider us YOUR development partner.",
    },
    {
      title: "IT Consultancy",
      icon: consultancyIcon,
      description:
        "We offer consultation in each and every aspect that makes your transformation to the digital world easier. It includes, discovery calls, idea brainstorming, process understanding and strategy development.",
    },
  ];
  const wonderingProcess = [
    {
      num: 1,
      heading: "Discovery",
      description:
        "In this stage we map out your software and determine together what features should be included in your scope.",
      img: bgImg1,
    },
    {
      num: 2,
      heading: "UX / UI Design",
      description:
        "Software starts to come to life stage! It includes flowcharts, and user journey mapping, and then we begin designing the software interface.",
      img: bgImg2,
    },
    {
      num: 3,
      heading: "Front-end coding",
      description:
        "This stage involves turning your design into code. Once we've coded the front end, you'll receive an invite to review it.",
      img: bgImg3,
    },
    {
      num: 4,
      heading: "Functionality code",
      description:
        "Now it's time to bring each feature to life! As we code each functionality we will send you the updated software for review.",
      img: bgImg4,
    },
    {
      num: 5,
      heading: "Q / A Testing",
      description:
        "At this point, the software is ready for testing. There are always bugs to deal with, but we plan for that! We will work together with you to ensure the product is totally up to par and ready for a public release.",
      img: bgImg2,
    },
    {
      num: 6,
      heading: "Beta Launch",
      description:
        "Here we go! Now it's time to release Ist Version of your software to the public. If it's a mobile app then we will submit it to Apple and Google for review.",
      img: bgImg3,
    },
    {
      num: 7,
      heading: "Maintenance & Upgrade",
      description:
        "Now that your software is live we will maintain it to keep it up to date. You can count on us to handle any bugs, add new features, and ensure your users have precisely what they need.",
      img: bgImg1,
    },
  ];
  const remarks = [
    {
      img: clockEven,
      heading: <NumberCounter start={1000} end={1700} postFix="+" />,

      title: "Hours worked",
    },
    {
      img: faceSmile,
      heading: <NumberCounter end={80} postFix="+" />,
      title: "Happy clients",
    },
    {
      img: rocketLaunch,
      heading: <NumberCounter end={75} />,
      title: "Happy clients",
    },
    {
      img: award,
      heading: <NumberCounter end={12} />,
      title: "Design awards",
    },
  ];
  const amazingTeam = [
    {
      img: zeeshan,
      title: "Zeeshan Wajid",
      position: "Co-founder",
    },
    {
      img: umair,
      title: "Zain Amin",
      position: "Technical Team lead",
    },
    {
      img: zain,
      title: "Jamshaid Javaid",
      position: "Manager",
    },
  ];
  const businessGrowth = [
    {
      heading: "SecondLook ORTHO",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business1,
    },
    {
      heading: "Truck in Link",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business2,
    },
    {
      heading: "Atiba",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business3,
    },
    {
      heading: "Massimo Motor",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business4,
    },
    {
      heading: "American Clinic Online",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business5,
    },
    {
      heading: "Find My Car Parts",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business6,
    },
    {
      heading: "Academify",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business7,
    },
    {
      heading: "CatoFit",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business8,
    },
    {
      heading: "OhanaDoc",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business9,
    },
    {
      heading: "Coach Me",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business10,
    },
    {
      heading: "Iglu",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business11,
    },
    {
      heading: "Nuvv",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business12,
    },
    {
      heading: "Gluetology",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business13,
    },
    {
      heading: "Forex County",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business14,
    },
    {
      heading: "SecondLook ORTHO",
      description:
        "We are not intimidated by unconventional ideas. We have provided proven software solutions for Health Care, Nutrition & Fitness, Telehealth, Telemedicine, CRMs, E-commerce, Printing, Insurance, Real Estate, Social Networking, Blockchain Web3 and POS.We provide a technical solution by understanding our client's business and cater solution which fits exactly to their requirements as well as can be adapted and practiced easily.",
      img: business15,
    },
  ];
  const testimonials = [
    {
      img: profile1,
      title: "Alexis Kyprianou",
      description:
        "”We hired Raza and his team to develop for us our own software from concept to creation. Raza is very professional and always keeps everything documented very well. He went above and beyond for us and still is to this day. We managed to complete the project on time and has helped us map out the vision we had for it. Every client we show our software they've always had great things to say about it and that's all thanks to him & his team. If you're looking for a high-quality, professional & trustworthy software developer then look no further. Raza is my go-to guy for anything tech/software-related. Every single dollar was well spent. Looking forward to continuing this long-term partnership.”",
    },
    {
      img: profile2,
      title: "Monifa Clarke",
      description:
        "”Raza's dedication and commitment to delivering quality work were evident from the moment he joined our team. He was very hands-on in his approach and worked tirelessly to ensure that every aspect of our web and mobile app was user-friendly and easy to navigate. We are grateful for all of his contributions”",
    },
    {
      img: profile3,
      title: "Tracye Lawyer",
      description:
        "”I hired Raza for a very complex website development plan. My website involved quite a bit of encryption and HIPAA compliance for my clients. Everything that I asked for he was able to execute. He developed a plan before we began which included a timeline, pricing and specifics of what was going to be complete each month. He was always available anytime if I had any questions or concerns.I really don't know anything about website development and he explained everything clearly. He and his team were very thorough and professional during the developmental process. The pricing was realistic given the amount of work was done. I would highly recommend him for any website development. I will definitely use him again in the future.”",
    },
  ];

  const goToSlide = (index) => {
    setIsOpen(index);
  };
  const goToTeaam = (index) => {
    setTeamIndex(index);
  };
  const goToBusiness = (index) => {
    setCurrentIndex(index);
  };
  const goToTestimonials = (index) => {
    setRatingIndex(index);
  };
  const slidePrev = () => {
    setIsOpen((prevIndex) =>
      prevIndex === 0 ? positionsList.length - 1 : prevIndex - 1
    );
  };

  const slideNext = () => {
    setIsOpen((prevIndex) =>
      prevIndex === positionsList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? businessGrowth.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === businessGrowth.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevRating = () => {
    setRatingIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const nextRating = () => {
    setRatingIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleRatingChange = (value) => {
    setRatingValue(value);
  };
  const scrollTo = () => {
    scroll.scrollTo(2800);
  };
  const isLaptop = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % businessGrowth.length);
      setTeamIndex((prevIndex) => (prevIndex + 1) % amazingTeam.length);
      setRatingIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="landing-container" onClick={() => setIsNav(false)}>
      <div className="header">
        <div className="heading">
          <h1>Crafting Digital Excellence: Your Vision, Our Code</h1>
          <p>
            Techify is a one-stop IT service provider that will take care of all
            your mobile and web application development needs.
          </p>
          <div className="heading-buttons">
            <a href="tel:+92123456789">
              <PrimaryButton className="get-button">Get in touch</PrimaryButton>
            </a>
            <PrimaryButton onClick={scrollTo}>Our work</PrimaryButton>
          </div>
        </div>
        <ReactSVG src={main} />
      </div>
      <div className="what-we-do" id="our-positions">
        <div className="heading-col">
          <div className="heading">
            <h1>What we do</h1>
            <ReactSVG src={line} />
          </div>
          <p>
            We are technologists and entrepreneurs, so we understand both the
            technical and business challenges of creating digital products.
          </p>
          <div className="arrow-button">
            <ReactSVG src={arrowCircleLeft} onClick={slidePrev} />
            <div className="dots">
              {positionsList.map((el, index) => (
                <li
                  key={index}
                  className={`dot ${index === isOpen ? "active" : ""}`}
                  onClick={() => goToSlide(index)}
                ></li>
              ))}
            </div>
            <ReactSVG src={arrowCircleRight} onClick={slideNext} />
          </div>
        </div>
        <div className="positions">
          {positionsList.map((el, index) => {
            return (
              <>
                <div
                  className={isOpen === index ? "hide-content" : "slider-list"}
                  onClick={() => setIsOpen(index)}
                >
                  <h5>{el.title}</h5>
                  <ReactSVG src={el.icon} />
                </div>
                {isOpen === index && (
                  <div className="card" key={index}>
                    <ReactSVG src={el.icon} />
                    <div className="card-heading">
                      <h1>{el.title}</h1>
                      <p>{el.description}</p>
                      <hr />
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className="wondering-how-we-do" id="our-portfolio">
        <h1>Wondering how we do it?</h1>
        <p className="wondering-des">
          We provide a technical solution by understanding our client's business
          and cater solution which fits exactly to their requirements as well as
          can be adapted and practiced easily.
        </p>
        {isLaptop ? (
          <div className="wondering">
            {wonderingProcess.map((el) => {
              return (
                <div className="wondering-array">
                  <div className="num-heading">
                    <span>{el.num}</span>
                    <div className="headiang-des">
                      <h2>{el.heading}</h2>
                      <p>{el.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="wondering">
            {wonderingProcess.map((el) => {
              return (
                <div
                  className="wondering-array"
                  style={{
                    backgroundImage: `url(${el.img})`,
                    width: "200px",
                    height: "200px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="num-heading">
                    <span>{el.num}</span>
                    <div className="headiang-des">
                      <h2>{el.heading}</h2>
                      <p>{el.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="reamrks">
        {remarks.map((el) => {
          return (
            <div
              className="reamrks-array"
              style={{
                backgroundImage: `url(${el.img})`,
                width: "105px",
                height: "100px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h1>{el.heading}</h1>
              <p>{el.title}</p>
            </div>
          );
        })}
      </div>
      <div className="our-team" id="our-team">
        <h1>Our amazing team</h1>
        <div className="owner">
          <img src={owner} />
          <p>
            We create progress by designing and developing custom software,
            moble applications and websites. We're technologists and
            entrepreneurs, so we understand both the technical and bussiness
            challanges of creating digital products.
          </p>
          <h2>Raza Ali</h2>
          <h5>Founder</h5>
        </div>
        {isLaptop ? (
          <div className="team">
            {amazingTeam.map((el, index) => {
              return (
                <>
                  {teamIndex === index && (
                    <div className="team-array">
                      <img src={el.img} />
                      <div className="heading-position">
                        <h3>{el.title}</h3>
                        <h6>{el.position}</h6>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <div className="team-indicators">
              {amazingTeam.map((el, index) => (
                <li
                  key={index}
                  className={`dot ${index === teamIndex ? "active" : ""}`}
                  onClick={() => goToTeaam(index)}
                ></li>
              ))}
            </div>
          </div>
        ) : (
          <div className="team">
            {amazingTeam.map((el) => {
              return (
                <div className="team-array zoom">
                  <img src={el.img} />
                  <div className="heading-position">
                    <h3>{el.title}</h3>
                    <h6>{el.position}</h6>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="business-growth" id="business-growth ">
        {businessGrowth.map((el, index) => {
          return (
            <>
              {currentIndex === index ? (
                <div
                  key={index}
                  className={`slider ${index === currentIndex ? "active" : ""}`}
                >
                  <div className="slider-heading">
                    <h1>{el.heading}</h1>
                    <p>{el.description}</p>
                    <div className="slider-button laptop-button">
                      <a href="tel:+447466587405" className="">
                        <PrimaryButton className="button">
                          What we can do for you
                        </PrimaryButton>
                      </a>
                    </div>
                  </div>
                  <div className="slider-image">
                    <div className="img-bg">
                      <ReactSVG src={el.img} />
                    </div>
                    <h2 className="project-label">Projects we’ve worked on</h2>
                  </div>
                  <div className="bg-color"></div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
        <div className="arrow-button">
          <ReactSVG src={arrowCircleLeft} onClick={prevSlide} />
          <ReactSVG src={arrowCircleRight} onClick={nextSlide} />
        </div>
      </div>
      <div className="business-indicators">
        {businessGrowth.map((el, index) => (
          <li
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToBusiness(index)}
          ></li>
        ))}
      </div>
      <div className="slider-button mobile-button">
        <a href="tel:+447466587405" className="">
          <PrimaryButton className="button">
            What we can do for you
          </PrimaryButton>
        </a>
      </div>
      <div className="testimonials">
        <h1>Testimonials</h1>
        <div className="ratings">
          {testimonials.map((el, index) => {
            return (
              <>
                {ratingIndex === index ? (
                  <div className="rating-array">
                    <img src={el.img} />
                    <div className="rating-content">
                      <h2>{el.title}</h2>
                      <Rating
                        emptySymbol={<ReactSVG src={fillStar} />}
                        fullSymbol={<ReactSVG src={fillStar} />}
                        initialRating={ratingValue}
                        onChange={handleRatingChange}
                      />
                      <p>{el.description}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
          <ReactSVG src={qoutes} className="qouts" />
          <div className="arrow-button">
            <ReactSVG src={arrowCircleLeft} onClick={prevRating} />
            <ReactSVG src={arrowCircleRight} onClick={nextRating} />
          </div>
        </div>
      </div>
      <div className="rating-indicators">
        {testimonials.map((el, index) => (
          <li
            key={index}
            className={`dot ${index === ratingIndex ? "active" : ""}`}
            onClick={() => goToTestimonials(index)}
          ></li>
        ))}
      </div>
      <div className="bottom-button">
        <h2>Ready to launch your software project?</h2>
        <a href="mailto:info@techify.solutions">
          <PrimaryButton className="button">Get in touch</PrimaryButton>
        </a>
      </div>
    </div>
  );
};

export default Landing;
