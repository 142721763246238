import React, { useState, useEffect } from "react";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import { ReactSVG } from "react-svg";
import { logo } from "../../Assets";
import "./Header.scss";
import { Link } from "react-scroll";
const Header = ({ isNav, setIsNav }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const $nav = document.querySelector(".header-container");
      if ($nav) {
        setIsScrolled(window.scrollY > $nav.clientHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`header-container ${isScrolled ? "scrolled" : ""}`}>
      <div className="logo-menu">
        <ReactSVG src={logo} className="logo" />
        <div
          className={isNav ? "change" : "menu"}
          onClick={() => setIsNav(!isNav)}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <div id="open" className={isNav ? "open" : "nav-buttons"}>
        <ul>
          <li>
            <Link
              to="our-positions"
              activeClass="active"
              spy={true}
              smooth={true}
              delay={0}
              duration={500}
              onClick={() => setIsNav(false)}
            >
              What we do
            </Link>
          </li>
          <li>
            <Link
              to="our-portfolio"
              activeClass="active"
              spy={true}
              delay={0}
              smooth={true}
              duration={800}
              onClick={() => setIsNav(false)}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="our-team"
              activeClass="active"
              spy={true}
              smooth={true}
              delay={0}
              duration={1000}
              onClick={() => setIsNav(false)}
            >
              Team
            </Link>
          </li>
        </ul>
        <div className="header-buttons">
          <a href="tel:+447466587405">
            <PrimaryButton
              className="call-button"
              onClick={() => setIsNav(false)}
            >
              Call us
            </PrimaryButton>
          </a>
          <a href="mailto:info@techify.solutions">
            <PrimaryButton
              className="quote-button"
              onClick={() => setIsNav(false)}
            >
              Get a quote
            </PrimaryButton>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
