import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";
// import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Landing from "./Pages/Landing/Landing";
function App() {
  const [isNav, setIsNav] = useState(false);
  return (
    <div className="app-container">
      <Header isNav={isNav} setIsNav={setIsNav} />
      <Routes>
        <Route path="/*" element={<Landing setIsNav={setIsNav} />} />
      </Routes>
      <Footer setIsNav={setIsNav} />
    </div>
  );
}

export default App;
