import React from "react";
import { ReactSVG } from "react-svg";
import { facebookF, linkedinIn, logo, whatsapp } from "../../Assets";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Footer.scss";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
const Footer = ({ setIsNav }) => {
  const today = new Date();
  const year = today.getFullYear();
  const scrollTo = () => {
    scroll.scrollTo(0);
  };
  return (
    <div className="footer-container" onClick={() => setIsNav(false)}>
      <div className="list-icons">
        <div className="col1">
          <ReactSVG src={logo} className="logo" onClick={scrollTo} />
          <p>
            Techify is a one-stop IT service provider that will take care of all
            your mobile and web application development needs by providing
            sophisticated and high-end solutions which are innovative and
            creative. We transform your ideas into amazing products. There is a
            wide pool of latest technologies for which we are known for the
            best. We are trusted software development company providing each and
            every IT services under one roof focusing on niche segments
            including Mobile application development, Web application
            development, Web designing, Mobile app designing, E-commerce web
            development, CMS, Big Data, Blockchain, NFTs, Cloud consulting.
          </p>
          <div className="contact-button">
            <a href="tel:+447466587405">
              <PrimaryButton className="button">Contact us</PrimaryButton>
            </a>
          </div>
        </div>
        <div className="links-icons">
          <div className="col2">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <Link
                  to="our-portfolio"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  delay={0}
                  duration={1000}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="business-growth"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  delay={0}
                  duration={1000}
                >
                  Services
                </Link>
              </li>
            </ul>
          </div>
          <div className="col3">
            <h4>Resources</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com/techify.software.development.company">
                <div className="icon">
                  <ReactSVG src={facebookF} />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/techi-fy/">
                <div className="icon">
                  <ReactSVG src={linkedinIn} />
                </div>
              </a>
              <a href="tel:+447466587405">
                <div className="icon">
                  <ReactSVG src={whatsapp} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="rights">Copyright © Techify {year}.</p>
    </div>
  );
};

export default Footer;
